export const MEETING_FOR_AGENDA_FRAGMENT = `
fragment meetingForAgendaFragment on Meeting {
    uid
    schemaCode
    isCancelled
    readOnly
    subject
    description
    location
    startTime
    endTime
    startTimestamp
    endTimestamp
    timeZoneName
    creatorUser {
        uid
        firstName
        lastName
        jobTitle
        employerName
        pictureFileResource {
            uid
            schemaCode
            path
        }
    }
    participants {
        uid
        state
        user {
            uid
            firstName
            lastName
            jobTitle
            employerName
            pictureFileResource {
                uid
                schemaCode
                path
            }
        }
    }
}
`;
